

















































































































































































































































import axios from 'axios';
import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Channel from '@/components/Channel.vue';
import Loading from '@/components/Loading.vue';
import Media from 'vue-media';
import ExtensionEdit from '@/components/ExtensionEdit.vue';

interface ExtensionInfo {
  admin_ids: string[];
  owner_id: string;
  name: string;
  extension_id: string;
  extension_secret: string;
  admin_url: string;
}

class UserInfo {
  public email: string;
  public user_id: string; // tslint:disable-line:variable-name
  public profile_image_url: string; // tslint:disable-line:variable-name
}

enum ExtensionPanel {
  Admin = "admin",
  Default = "default",
  Edit = "edit",
  Live = "live"
}

@Component({
  name: 'ExtensionManager',
  components: { ExtensionEdit, Modal, Media, Channel, Loading }
})
export default class ExtensionManager extends Vue {
  get isOwner() {
    return this.$auth?.user && this.extensionInfo.owner_id === this.$auth.user.id;
  }

  get adminUrl() {
    return this.adminUrlMapping[this.$route.params.extension_id];
  }

  get oneClickUrl() {
    return `${window.location.origin}/login/twitch/${this.$route.params.extension_id}`;
  }

  public get adminEnvironment() {
    if (
      window.location.origin.indexOf('dev-portal.staging.muxy.io') !== -1 ||
      window.location.origin.indexOf('dev.staging.muxy.io') !== -1 ||
      window.location.origin.indexOf('localhost') !== -1
    ) {
      return 'SandboxAdmin';
    } else {
      return 'Admin';
    }
  }
  public $refs: {
    frame: HTMLIFrameElement;
    oneClickInput: HTMLInputElement;
  };

  public extensionInfo: ExtensionInfo = {
    admin_ids: [], // tslint:disable-line:variable-name
    owner_id: '', // tslint:disable-line:variable-name
    name: '',
    extension_id: '',
    extension_secret: '',
    admin_url: ''
  };

  private mobileCutoffWidth = 813;
  private ExtensionPanel = ExtensionPanel;
  public showPanel: boolean = false;
  public panelDisplay: string = ExtensionPanel.Admin;
  public channelList: any[] = [];
  public loading: boolean = true;

  public adminUrlMapping: { [s: string]: string } = {};

  public newAdminID: string = '';
  public newAdminInfo: UserInfo[] = [];
  public changes: any = {
    added: {},
    removed: {}
  };

  public message = '';
  public errorMessage: string = '';

  public copyButtonText: string = "Copy";
  public copyButtonTimeout: number = 0;

  public idToUserInfoMapping: { [s: string]: UserInfo } = {};

  panelButtonClass(currentPanel: string) {
    if (currentPanel === this.panelDisplay) {
      return "btn-disabled";
    }

    return "btn-secondary";
  }

  private mediaEnter(mediaQueryString: string) {
    this.panelDisplay = ExtensionPanel.Admin;
  }

  private mediaLeave(mediaQueryString: string) {
    this.panelDisplay = ExtensionPanel.Default;
  }

  public closeModal() {
    this.showPanel = false;
    this.panelDisplay = ExtensionPanel.Default;
  }

  public showLive() {
    this.loadLiveChannels();
    this.panelDisplay = ExtensionPanel.Live;
    this.showPanel = true;
  }

  public showAdmin() {
    this.panelDisplay = ExtensionPanel.Admin;
    this.showPanel = true;
  }

  public showEditExtension() {
    this.panelDisplay = ExtensionPanel.Edit;
    this.showPanel = true;
  }

  public created() {
    this.loadAdmins();
    this.loadAdminUrlMappings();
  }

  public mounted() {
    // Load extension admin URL from local storage if there isn't a value received from the DB
    if (!this.extensionInfo.admin_url) {
      this.extensionInfo.admin_url = this.adminUrl;
    }
  }

  public removeAdmin(id: string) {
    Vue.delete(this.changes.added, id);
    Vue.set(this.changes.removed, id, true);

    this.saveAdminChanges();
  }

  public addAdmin(id: string) {
    this.newAdminInfo = [];

    const alreadyExists = this.extensionInfo.admin_ids.find((a) => a === id);
    if (alreadyExists) {
      return;
    }

    Vue.delete(this.changes.removed, id);
    Vue.set(this.changes.added, id, true);
    this.newAdminID = '';

    this.saveAdminChanges();
  }

  public saveAdminChanges() {
    const added = [];
    for (const ad of Object.keys(this.changes.added)) {
      added.push(ad);
    }

    const removed = [];
    for (const rem of Object.keys(this.changes.removed)) {
      removed.push(rem);
    }

    this.$api({
      method: 'POST',
      url: `/api/extension/${this.$route.params.extension_id}/admins`,
      data: {
        add: added,
        remove: removed
      }
    })
      .catch((error) => {
        this.errorMessage = error.response.data.error;
      })
      .finally(() => {
        this.loadAdmins();
      });
  }

  public async searchAdmins(query: string) {
    this.newAdminInfo = [];
    this.errorMessage = '';

    const search = async (parameters: string) => {
      const resp = await this.$api({
        method: 'GET',
        url: `/api/user/search?${parameters}`
      });

      if (!resp.data) {
        this.errorMessage = 'No user associated with that email address or twitch username';
        return;
      }

      this.newAdminInfo = resp.data.results;
      for (const info of resp.data.results) {
        Vue.set(this.idToUserInfoMapping, info.user_id, info);
      }
    };

    if (query.indexOf('@') !== -1) {
      return search(`email=${encodeURIComponent(query)}`);
    } else {
      const tokenResponse = await this.$api({
        method: 'GET',
        url: `/api/frontend_token`
      });

      const client = tokenResponse.data?.client;
      const token = tokenResponse.data?.token;

      const searchResult = await axios({
        url: `https://api.twitch.tv/helix/users?login=${query}`,
        headers: {
          Accept: 'application/vnd.twitchtv.v5+json',
          'Client-ID': client,
          'Authorization': `Bearer ${token}`
        }
      })

      if (searchResult.data.data.length) {
        return search(`email=${encodeURIComponent(query)}&id=${searchResult.data.data[0].id}`);
      } else {
        return search(`email=${encodeURIComponent(query)}`);
      }
    }
  }

  public loadAdmins() {
    this.$api({
      url: `/api/extension/${this.$route.params.extension_id}`
    }).then<any>((resp) => {
      this.extensionInfo = resp.data;

      const admins = this.extensionInfo.admin_ids.slice();
      admins.push(this.extensionInfo.owner_id);

      this.loadUserInformation(admins);

      this.changes.removed = {};
      this.changes.added = {};
    });
  }

  public loadUserInformation(users: string[]) {
    const filteredUsers = users.filter((x) => {
      return !this.idToUserInfoMapping[x];
    });

    if (filteredUsers.length === 0) {
      return;
    }

    this.$api({
      method: 'POST',
      url: `/api/user`,
      data: users
    }).then<any>((resp) => {
      if (!resp.data) {
        return;
      }

      resp.data.forEach((user: UserInfo) => {
        if (!user) return;
        Vue.set(this.idToUserInfoMapping, user.user_id as string, user);
      });
    });
  }

  public email(id: string): string {
    const val = this.idToUserInfoMapping[id];
    if (val) {
      return val.email || '';
    }

    return '';
  }

  public logo(id: string): string {
    const val = this.idToUserInfoMapping[id];
    if (val) {
      return val.profile_image_url || '';
    }

    return '';
  }

  public loadAdminUrlMappings() {
    const ls = window.localStorage;
    const value = ls.getItem('admin_mappings');
    if (!value) {
      this.adminUrlMapping = {};
      return;
    }

    const conf = JSON.parse(value);

    this.adminUrlMapping = conf || {};
  }

  public copyOneClick() {
    const node = this.$refs.oneClickInput;
    node.disabled = false;
    node.select();
    document.execCommand("copy");
    node.disabled = true;

    if (window) {
      window.getSelection()?.removeAllRanges();
    }

    this.copyButtonText = "Copied";

    clearTimeout(this.copyButtonTimeout);
    this.copyButtonTimeout = window.setTimeout(() => {
      this.copyButtonText = "Copy";
    }, 2000);
  }

  public openAdminWindow() {
    const value = JSON.stringify(this.adminUrlMapping);
    window.localStorage.setItem('admin_mappings', value);

    const adminWin = window.open(
      `/admin/?extensionId=${this.$route.params.extension_id}&aurl=${this.extensionInfo.admin_url}`, // tslint:disable-line:max-line-length
      `${this.$route.params.extension_id}AdminPage`,
      'menubar=0,location=0,toolbar=0'
    );

    if (this.isOwner) {
      // Save admin URL in database
      this.$api({
        method: 'POST',
        url: `/api/extension/${this.extensionInfo.extension_id}`,
        data: this.extensionInfo
      });
    }
  }

  public sendAuthTokenToIframe() {
    this.$api({
      url: `/api/extension/${this.$route.params.extension_id}/jwt`
    }).then<any>((resp) => {
      window.addEventListener('message', (msg) => {
        if (!msg || !msg.data) {
          return;
        }

        if (msg.data.type === 'connect') {
          if (msg.data.id === this.$route.params.extension_id) {
            const frame = this.$refs.frame;
            if (!frame || !frame.contentWindow) {
              return;
            }

            frame.contentWindow.postMessage(
              {
                type: 'jwt',
                jwt: resp.data.jwt
              },
              '*'
            );

            setInterval(() => {
              this.$api({
                url: `/api/extension/${this.$route.params.extension_id}/jwt`
              }).then<any>((refresh) => {
                if (!frame || !frame.contentWindow) {
                  return;
                }

                frame.contentWindow.postMessage(
                  {
                    type: 'jwt',
                    jwt: refresh.data.jwt
                  },
                  '*'
                );
              });
            }, 1000 * 60 * 15);
          } else {
            this.message = `Mismatched extension ID in admin panel and
              currently selected extension, refusing to send jwt`;
          }
        }
      });
    });
  }

  public loadLiveChannels() {
    let channels: any[] = [];
    axios({
      url: `https://api.twitch.tv/extensions/${this.$route.params.extension_id}/live_activated_channels`,
      headers: {
        'Client-ID': this.$route.params.extension_id
      }
    })
      .then<any>((resp) => {
        channels = resp.data.channels;
        const userLogins = channels.map((c) => `user_login=${c.username.toLowerCase()}`).join('&');
        if (userLogins) {
          return axios({
            url: `https://api.twitch.tv/helix/streams?user_login=${userLogins}`,
            headers: {
              'Client-ID': this.$route.params.extension_id
            }
          });
        }
      })
      .then<any>((resp) => {
        channels = resp.data.data;
        const channelIds = channels.map((c: any) => `id=${c.user_id}`).join('&');

        return axios({
          url: `https://api.twitch.tv/helix/users?${channelIds}`,
          headers: {
            'Client-ID': this.$route.params.extension_id
          }
        });
      })
      .then<any>((resp) => {
        const { data } = resp.data;
        const res: any[] = [];

        channels.forEach((chan) => {
          const chanData = data.find((d: any) => d.id === chan.user_id);
          if (!chanData) {
            res.push(chan);
          } else {
            res.push(
              Object.assign(chanData, {
                thumbnail_url: chan.thumbnail_url,
                title: chan.title,
                viewer_count: chan.viewer_count
              })
            );
          }
        });

        this.channelList = res;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
}
