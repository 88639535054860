













































import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';

interface ExtensionInfo {
  admin_ids: string[];
  owner_id: string;
  name: string;
  extension_id: string;
  extension_secret: string;
}

@Component({
  name: 'ExtensionEdit',
  components: { Modal }
})
export default class ExtensionEdit extends Vue {
  public confirmDelete: boolean = false;
  public editExtensionMessage: string = '';
  public editingExtension: ExtensionInfo = {
    admin_ids: [], // tslint:disable-line:variable-name
    owner_id: '', // tslint:disable-line:variable-name
    name: '',
    extension_id: '',
    extension_secret: ''
  };

  @Prop() public extensionInfo: ExtensionInfo;

  public mounted() {
    this.editingExtension = Object.assign({}, this.extensionInfo);
  }

  public editExtension() {
    // Post updated extension info
    this.$api({
      method: 'POST',
      url: `/api/extension/${this.editingExtension.extension_id}`,
      data: this.editingExtension
    }).then < any > (() => {
      this.editExtensionMessage = 'Changes saved.';
      this.extensionInfo.name = this.editingExtension.name;
      this.extensionInfo.extension_secret = this.editingExtension.extension_secret;
    }).catch < any > ((req: XMLHttpRequest) => {
      this.editExtensionMessage = req.response.data.error;
    });
  }

  public confirmDeleteExtension() {
    this.confirmDelete = true;
  }

  public closeDelete() {
    this.confirmDelete = false;
  }

  public deleteExtension() {
    this.$api({
      method: 'DELETE',
      url: `/api/extension/${this.editingExtension.extension_id}`
    }).then < any > (() => {
      this.editExtensionMessage = 'Extension deleted.';
      this.$router.push('/dashboard');
    }).catch < any > ((req: XMLHttpRequest) => {
      this.editExtensionMessage = req.response.data.error || 'Unable to delete extension.';
      this.confirmDelete = false;
    });
  }
}
