





















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'Channel'
})
export default class Channel extends Vue {
  @Prop() public channel!: any;

  public thumbnailURL(url: string) {
    return url.replace('{width}', '200').replace('{height}', '100');
  }
}
